<template>
  <section class="contacts-list">
    <ErrorView v-if="error" :error-type="error" />

    <SkeletonList
      v-else-if="loadingContacts"
      :item-count="skeletonUiItemCount"
    />

    <EmptyContactGroup
      v-else-if="showEmptyContactGroup"
      :active-search-query="activeQuery"
      :group-id="activeContactGroup.id"
    />

    <template v-else-if="contacts.length">
      <template v-for="group in groupedContacts">
        <h4
          class="panel__title panel__title--list"
          :key="group.title"
          v-if="group.title"
        >
          {{ group.title }}
        </h4>
        <transition-group
          tag="ul"
          name="list"
          :class="densityOption.class"
          :key="`${group.title}-list`"
        >
          <ContactListItem
            v-for="(contact, i) in group.contacts"
            :contact="contact"
            :id="`${group.title}-${i}`"
            :key="`${group.title}-${i}`"
          />
        </transition-group>
      </template>
    </template>
  </section>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import ContactListItem from '@/components/ContactListItem/ContactListItem';
  import SkeletonList from '@/components/SkeletonList/SkeletonList';
  import groupMixin from '@/mixins/group-mixin';
  import EmptyContactGroup from '@/components/EmptyContactGroup/EmptyContactGroup';
  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView';

  export default {
    components: {
      ContactListItem,
      SkeletonList,
      EmptyContactGroup,
      ErrorView,
    },
    mixins: [groupMixin],
    props: {
      contacts: {
        type: Array,
        required: true,
      },
    },
    computed: {
      ...mapGetters([
        'loadingContacts',
        'loadingContactsError',
        'loadingContactGroupsError',
        'pagination/currentPageSize',
        'activeContactGroup',
      ]),
      ...mapGetters('layout', ['densityOption']),
      skeletonUiItemCount() {
        return this['pagination/currentPageSize'] || 4;
      },
      groupedContacts() {
        if (!this.densityOption.groupItems) {
          return [{ contacts: this.contacts }];
        }

        return Object.entries(
          this.groupByFirstCharacter(
            this.contacts,
            '#',
            ({ display_name }) => display_name
          )
        )
          .sort(([a], [b]) => a.toLowerCase().localeCompare(b.toLowerCase()))
          .map(([title, contacts]) => ({ title, contacts }));
      },
      activeQuery() {
        return this.$route?.query?.query || '';
      },
      error() {
        if (this.loadingContactsError) {
          return ErrorType.LoadingContactsError;
        } else if (this.loadingContactGroupsError) {
          return ErrorType.LoadingContactGroupsError;
        } else {
          return null;
        }
      },
      showEmptyContactGroup() {
        return (
          !this.loadingContacts &&
          !this.loadingContactsError &&
          !this.contacts.length
        );
      },
    },
    methods: {
      ...mapActions(['deselectAllContacts']),
    },
  };
</script>

<style src="./ContactList.scss" lang="scss" />
